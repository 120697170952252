import type { BackgroundPaint, FillPaint } from 'mapbox-gl';
import type { GeoJSONSource, LayerProps, SourceProps } from 'react-map-gl';
import { Source, Layer } from 'react-map-gl';

export interface GeometryLayerProps {
  id?: string;
  data: GeoJSONSource;
  source?: Partial<Omit<SourceProps, 'type'>>;
  layer?: Partial<Omit<LayerProps, 'type'>>;
}

export function GeometryLayer({
  id = 'geometry',
  data,
  source = {},
  layer = {},
}: GeometryLayerProps) {
  return (
    <Source id={`${id}-source`} type="geojson" {...source} data={data as any}>
      {/* @ts-expect-error */}
      <Layer
        id={`${id}-layer`}
        type="fill"
        paint={{
          'fill-color': '#3cb043',
          'fill-opacity': 0.2,
        } as BackgroundPaint & FillPaint}
        {...layer}
      />
    </Source>
  );
}
