import type { accentColors } from '@radix-ui/themes/props';
import { Badge, Skeleton, Strong } from '@radix-ui/themes';

import type { ServiceType } from '@/data';

const i18n = {
  en: {
    services: {
      LAWNBASE: 'Land Care',
      LAWNCARE: 'Lawn Care',
      LAWNPLUS: 'Lawn Care +',
    } as Record<ServiceType, string>,
    color: {
      LAWNBASE: 'jade',
      LAWNCARE: 'grass',
      LAWNPLUS: 'grass',
    } as Record<ServiceType, (typeof accentColors)[number]>,
  },
};

interface ServiceTypeBadgeProps {
  type: ServiceType;
  size?: '1' | '2' | '3';
  loading?: boolean;
}

export default function ServiceTypeBadge({
  loading = false,
  type,
  size = '2',
}: ServiceTypeBadgeProps) {
  return (
    <Skeleton loading={loading}>
      <Badge size={size} variant="solid" color={i18n.en.color[type]}>
        <Strong>{i18n.en.services[type]}</Strong>
      </Badge>
    </Skeleton>
  );
}

export function ServiceTypeBadgeLoading({
  type = 'LAWNBASE',
  size,
}: ServiceTypeBadgeProps) {
  return (
    <Skeleton>
      <ServiceTypeBadge type={type} size={size} />
    </Skeleton>
  );
}
