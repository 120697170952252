import { Card, Inset, Separator, Skeleton } from '@radix-ui/themes';

import { PropertyMap as Map } from '@/components/maps/PropertyMap';
import type { Coordinates, Address, Geometry } from '@/data';

const i18n = {
  en: {
    descriptionStart: 'map of',
  },
};

export default function PropertyMap({
  coordinates,
  address,
  layers,
  loading = false,
  size = 'auto',
}: {
  coordinates?: Pick<Coordinates, 'latitude' | 'longitude'>;
  address?: Pick<Address, 'formatted'>;
  layers?: Pick<Geometry, 'sqft' | 'geometry'>;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large' | 'auto';
}) {
  return (
    <Skeleton loading={loading} width="100%" minHeight="300px">
      <figure>
        <Map
          className={
            size === 'small'
              ? 'h-[300px] min-h-[300px]'
              : size === 'medium'
                ? 'h-[400px] min-h-[400px]'
                : size === 'large'
                  ? 'h-[500px] min-h-[500px]'
                  : 'h-[300px] min-h-[300px]'
          }
          latitude={coordinates?.latitude!}
          longitude={coordinates?.longitude!}
        />

        <figcaption className="sr-only">
          {i18n.en.descriptionStart} {address?.formatted}
        </figcaption>
      </figure>
    </Skeleton>
  );
}

export function PropertyMapInset({
  coordinates,
  address,
  layers,
  isLoading = false,
}: {
  coordinates?: Pick<Coordinates, 'latitude' | 'longitude'>;
  address?: Pick<Address, 'formatted'>;
  layers?: Pick<Geometry, 'sqft' | 'geometry'>;
  isLoading?: boolean;
}) {
  return (
    <Inset clip="padding-box" side="top" pb="current">
      {isLoading ? (
        <Skeleton width="100%" minHeight="300px" />
      ) : (
        <figure>
          <Map
            className="h-[300px] min-h-[300px]"
            latitude={coordinates?.latitude!}
            longitude={coordinates?.longitude!}
          />

          <figcaption className="sr-only">
            {i18n.en.descriptionStart} {address?.formatted}
          </figcaption>
        </figure>
      )}

      <Separator size="4" />
    </Inset>
  );
}

// export function PropertyMapImage({
//   coordinates,
//   address,
//   isScaled = true,
// }: {
//   coordinates: Pick<Coordinates, 'latitude' | 'longitude'>;
//   address: Pick<Address, 'formatted'>;
//   isScaled?: boolean;
// }) {
//  return null
// }
