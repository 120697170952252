'use client';

import Link from 'next/link';
import {
  Button,
  Callout,
  Card,
  Flex,
  Heading,
  Skeleton,
} from '@radix-ui/themes';

import type { RPCError, RPCOutputs } from '@/types/rpc';
import { api } from '@/data/client';

import LoadingView from '@/views/components/Loading';
import ErrorView from '@/views/components/Error';
import PropertyAddress from '@/views/components/PropertyAddress';
import PreviewService from '@/views/components/PreviewService';
import { PropertyMapInset } from '@/views/components/PropertyMap';
import { TriangleAlertIcon } from 'lucide-react';

const i18n = {
  en: {
    error: 'Oh no... it looks like there was an error. Please try again.',
    actions: {
      viewProperty: 'view property',
    },
  },
};

export default function PreviewProperty({
  loading = false,
  propertyId = '',
  property,
}: {
  loading?: boolean;
  propertyId?: string;
  property?: NonNullable<RPCOutputs['property']['getProperty']>;
}) {
  const { data, isLoading, isError, error } = api.property.getProperty.useQuery(
    {
      propertyId,
    },
    {
      enabled: !loading && !!propertyId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: !!property === false,
      initialData: property,
    },
  );

  const dataLoading = loading || isLoading;

  if (!data && !dataLoading) {
    if (isError) {
      return <ErrorView error={error as RPCError} />;
    }

    return (
      <Callout.Root color="red">
        <Callout.Icon>
          <TriangleAlertIcon />
        </Callout.Icon>
        <Callout.Text>{i18n.en.error}</Callout.Text>
      </Callout.Root>
    );
  }

  return (
    <section>
      <Card size="3">
        <PropertyMapInset
          isLoading={dataLoading}
          coordinates={data?.coordinates}
          address={data?.address}
        />

        <div className="@container">
          <Flex direction="column" gap="8" justify="center">
            <div className="grid grid-flow-row gap-4 @sm:grid-flow-col">
              <header className="flex items-center justify-start p-2 @sm:justify-center">
                <Heading as="h2">
                  <PropertyAddress
                    loading={dataLoading}
                    address={data?.address}
                  />
                </Heading>
              </header>

              <PreviewService
                loading={dataLoading}
                service={{
                  date: new Date(),
                  type: 'LAWNCARE',
                  status: 'SCHEDULED',
                }}
              />
            </div>

            <footer>
              <Flex justify="center" align="center">
                <Skeleton loading={dataLoading}>
                  <Button variant="soft" size="2" asChild>
                    <Link href={`/app/properties/${data?.id}`}>
                      {i18n.en.actions.viewProperty}
                    </Link>
                  </Button>
                </Skeleton>
              </Flex>
            </footer>
          </Flex>
        </div>
      </Card>
    </section>
  );
}
