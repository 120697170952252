import type { accentColors } from '@radix-ui/themes/props';
import { Badge, Skeleton } from '@radix-ui/themes';

import type { ServiceStatus } from '@/data';

const i18n = {
  en: {
    status: {
      PENDING: 'pending',
      SCHEDULED: 'scheduled',
      STARTED: 'started',
      COMPLETED: 'completed',
      CANCELLED: 'cancelled',
      DISPUTED: 'disputed',
    } as Record<ServiceStatus, string>,
    color: {
      PENDING: 'indigo',
      SCHEDULED: 'blue',
      STARTED: 'grass',
      COMPLETED: 'green',
      CANCELLED: 'tomato',
      DISPUTED: 'orange',
    } as Record<ServiceStatus, (typeof accentColors)[number]>,
  },
};

export default function ServiceStatusBadge({
  status,
  size = '1',
}: {
  status: ServiceStatus;
  size: '1' | '2' | '3';
}) {
  return (
    <Badge size={size} color={i18n.en.color[status]}>
      {i18n.en.status[status]}
    </Badge>
  );
}

export function ServiceStatusBadgeLoading() {
  return <Skeleton width="80px" height="20px" />;
}
