import { Map, PropertyLayer } from '@/components/maps';

export interface PropertyMapProps {
  zoom?: number;
  latitude: number;
  longitude: number;
  properties?: any[];
  className?: string;
  minHeight?: number;
}

export function PropertyMap({
  zoom,
  latitude,
  longitude,
  properties = [],
  className,
  minHeight,
}: PropertyMapProps) {
  return (
    <Map
      latitude={latitude}
      longitude={longitude}
      zoom={zoom}
      className={className}
      minHeight={minHeight}
    >
      {properties.map((geojson, index) => (
        <PropertyLayer key={index} data={geojson} />
      ))}
    </Map>
  );
}
