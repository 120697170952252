'use client';

import type { MapLayerMouseEvent, MapProps, MapRef } from 'react-map-gl';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import MapboxMap from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import { useThemeState } from '@/hooks/app';
import { resolveColorScheme } from '@/lib/utils/theme';

import 'mapbox-gl/dist/mapbox-gl.css';

export type { MapRef } from 'react-map-gl';

mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!;

export interface IMapProps extends MapProps {
  longitude?: number;
  latitude?: number;
  zoom?: number;
  minHeight?: number;
  className?: string;
  bounds?: {
    ne: [number, number];
    sw: [number, number];
  };
  onClick?: (event: MapLayerMouseEvent) => void;
}

const styles = {
  light: 'mapbox://styles/mapbox/light-v11',
  dark: 'mapbox://styles/mapbox/dark-v10',
};

export const Map = forwardRef<MapRef, PropsWithChildren<IMapProps>>(
  function MapComponent(
    {
      longitude = -70.9,
      latitude = 42.35,
      zoom = 17,
      bounds,
      minHeight = 100,
      children,
      className,
      ...props
    }: PropsWithChildren<IMapProps>,
    ref,
  ) {
    const { colorScheme } = useThemeState();
    const theme = resolveColorScheme(colorScheme);

    return (
      <div className={className}>
        <MapboxMap
          ref={ref}
          {...props}
          style={props.style || { width: '100%', height: '100%' }}
          initialViewState={
            props.initialViewState || {
              longitude,
              latitude,
              zoom,
              bounds: bounds
                ? [
                    [bounds.sw[0], bounds.sw[1]],
                    [bounds.ne[0], bounds.ne[1]],
                  ]
                : undefined,
            }
          }
          mapboxAccessToken={mapboxgl.accessToken}
          mapStyle={styles[theme] || styles.light}
          mapLib={mapboxgl}
        >
          {children}
        </MapboxMap>
      </div>
    );
  },
);

Map.displayName = 'Map';
