import { Flex, Skeleton, Text } from '@radix-ui/themes';

import type { Address } from '@/data';

export default function PropertyAddress({
  loading = false,
  address,
}: {
  loading?: boolean;
  address?: Pick<Address, 'street' | 'city' | 'state' | 'postal'>;
}) {
  const {
    street = '[street]',
    city = '[city]',
    state = '[state]',
    postal = '[postal]',
  } = address || {};

  return (
    <Flex direction="column">
      <Text as="span" size="6">
        <Skeleton loading={loading}>{street}</Skeleton>
      </Text>
      <Text as="span" color="gray" weight="medium" size="5">
        <Skeleton loading={loading}>
          {[city, state, postal].join(', ')}
        </Skeleton>
      </Text>
    </Flex>
  );
}
