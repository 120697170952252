import { Flex, Text, Strong } from '@radix-ui/themes';

const i18n = {
  locale: 'en-US',
};

export function Time({ date }: { date: Date }) {
  const [time, cycle] = date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .split(' ');

  return (
    <Flex gap="1" align="baseline">
      <Text size="7" as="span">
        <Strong>{time}</Strong>
      </Text>
      <Text size="4" as="span">
        <Strong>{cycle}</Strong>
      </Text>
    </Flex>
  );
}

export function Day({ date }: { date: Date }) {
  const [month, day] = date
    .toLocaleDateString(i18n.locale, {
      month: 'long',
      day: 'numeric',
    })
    .split(' ');

  return (
    <Flex direction="column" justify="center" align="center">
      <Text size="4" as="span">
        <Strong>{month}</Strong>
      </Text>
      <Text size="9" as="span">
        <Strong>{day}</Strong>
      </Text>
    </Flex>
  );
}

export function DayLoading() {
  return (
    <Flex direction="column" justify="center" align="center">
      <Text size="4" as="span">
        <Strong>---</Strong>
      </Text>
      <Text size="9" as="span">
        <Strong>---</Strong>
      </Text>
    </Flex>
  );
}

export function TimeLoading() {
  return (
    <Flex gap="1" align="baseline">
      <Text size="7" as="span">
        <Strong>---</Strong>
      </Text>
      <Text size="4" as="span">
        <Strong>---</Strong>
      </Text>
    </Flex>
  );
}
