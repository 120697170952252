import { Source, Layer } from 'react-map-gl';
import type { GeoJSONSource, LayerProps, SourceProps } from 'react-map-gl';

export interface PropertyLayerProps {
  data: GeoJSONSource;
  source?: Omit<SourceProps, 'type'>;
  layer?: Omit<LayerProps, 'type'>;
}

export function PropertyLayer({
  data,
  source = {},
  layer = {},
}: PropertyLayerProps) {
  return (
    <Source id="property-source" type="geojson" {...source} data={data as any}>
      {/* @ts-ignore */}
      <Layer
        {...{
          id: 'property-layer',
          type: 'fill',
          paint: {
            'fill-color': '#3cb043',
            'fill-opacity': 0.2,
          },
          ...layer,
        }}
      />
    </Source>
  );
}
