import type { PropsWithChildren } from 'react';
import { Box, Flex, Card, Separator, Skeleton } from '@radix-ui/themes';

import type { Service } from '@/data';
import ServiceTypeBadge, {
  ServiceTypeBadgeLoading,
} from '@/views/components/ServiceTypeBadge';
import ServiceStatusBadge, {
  ServiceStatusBadgeLoading,
} from '@/views/components/ServiceStatusBadge';
import {
  Day,
  DayLoading,
  Time,
  TimeLoading,
} from '@/views/components/DateTime';

export default function PreviewService({
  loading = false,
  service,
  children,
}: PropsWithChildren<{
  loading?: boolean;
  service: Pick<Service, 'status' | 'date' | 'type'>;
}>) {
  return (
    <div className="relative mt-6">
      <div
        className="absolute z-10 flex w-1/2 justify-center sm:w-[124px]"
        style={{ top: -12 }}
      >
        <Card
          className="flex items-stretch justify-center"
          style={{ padding: 0 }}
        >
          <Flex>
            {loading ? (
              <ServiceStatusBadgeLoading />
            ) : (
              <ServiceStatusBadge status={service.status} size="2" />
            )}
          </Flex>
        </Card>
      </div>

      <Card>
        <time dateTime={loading ? undefined : service.date?.toUTCString()}>
          <Flex
            direction="row"
            justify="between"
            gap="1"
            align="center"
            style={{ height: 96 }}
          >
            <Flex
              flexGrow="1"
              direction="column"
              justify="center"
              align="center"
              style={{ height: 96 }}
              className="w-full min-w-24 sm:me-3 sm:w-auto"
            >
              {loading ? <DayLoading /> : <Day date={service.date!} />}
            </Flex>

            <Separator orientation="vertical" size="4" />

            <Box flexGrow="1" className="h-full w-full py-2">
              <Flex
                gap="3"
                direction="column"
                align="center"
                justify="between"
                className="h-full"
              >
                <ServiceTypeBadge loading={loading} type={service?.type} />
                {loading ? <TimeLoading /> : <Time date={service.date!} />}
              </Flex>
            </Box>
          </Flex>
        </time>

        {children && (
          <>
            <Separator orientation="horizontal" size="4" />
            {children}
          </>
        )}
      </Card>
    </div>
  );
}
